html {
  font-size: 14px;
  margin: 0;
}

body {
  margin: 0;
  height: 100%;
  background: url(../images/main-bg.jpg) repeat-y;
  background-size: cover;
  
}

#root {
  margin: 0;
  /* width: 100%; */
  height: 100%;
}

* {
  box-sizing: border-box;
}

.x-margins {
  margin: 1rem;
}

.x-table {
  border-collapse: collapse;
}

.x-table th, .x-table td {
  padding: 0.25rem;
  border: 1px solid #c0c0c0;
}

.x-table th {
  background-color: #5050a0;
  color: #ffffff;
}

.x-table > tbody > tr:nth-child(even) {
  background-color: #e0e0e0;
}

.dashboard-name {
  padding-top: 2rem;
  color: #ffffff;
  text-align: center;
  margin: 0;
}

.header-section {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.header-section * {
  color: #ffffff;
}